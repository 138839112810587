import React from 'react'
import reactDom from 'react-dom'


import MenuContent from './MenuContent'


function Menu(props) {
    return reactDom.createPortal(
        <div className='menu absolute w-full'>

            <section>
                <MenuContent />
            </section>
            
        </div>,
        document.getElementById('menu')
    )
}

export default Menu
