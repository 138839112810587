import React, { useState } from 'react'

import cOne from '../../assets/ui/c1.svg'
import cTwo from '../../assets/ui/c2.svg'
import cThree from '../../assets/ui/c3.svg'
import cFour from '../../assets/ui/c4.svg'
import arrowWhite from '../../assets/ui/arrow-w.svg'
import projectOne from '../../assets/projects/project1/one.png'
import OpeningGrid from './OpeningGrid'
import SectionTwo from './SectionTwo'
import ContactSection from './ContactSection'
import BookSection from './BookSection'
import Menu from './Menu'
import Carousel from './Carousel'

function Home() {

    


    return (
        <div className='w-full'>

            <section>
                <OpeningGrid />
            </section>


            <section>
                <SectionTwo />
            </section>

            <section className='grid gap-28 lg:grid-flow-col lg:gap-8 py-20 border-2 border-transparent border-b-neutral-800'>
                <div className='flex flex-row justify-center'> <img src={cOne} alt='clients'></img></div>
                <div className='flex flex-row justify-center'> <img src={cTwo} alt='clients'></img></div>
                <div className='flex flex-row justify-center'> <img src={cThree} alt='clients'></img></div>
                <div className='flex flex-row justify-center'> <img src={cFour} alt='clients'></img></div>
            </section>

            <section>
                <div className=' h-72 md:h-96 w-full text-3xl md:text-8xl md:font-light flex flex-col justify-center px-8 lg:px-16 border-2 border-transparent border-b-neutral-800'>
                    <p>Recent work</p>
                </div>
            </section>


            <section>
                <Carousel />
            </section>

            <section className=' md:grid md:grid-flow-col md:divide-x text-3xl font-thin'>
                
                    <div className='flex flex-row justify-between items-end py-20 px-8 lg:px-16 mt-24 md:mt-0 border-2 border-transparent border-b-neutral-800 md:border-r-neutral-800'>
                        <p className='w-6'>Vivo Cinema</p>
                        <img src={arrowWhite} alt='arrow'></img>
                    </div>
                    <div className='flex flex-row justify-between items-end py-20 px-8 lg:px-16 mt-24 border-2 border-transparent border-b-neutral-800'>
                        <p>More <br/> work here</p>
                        <img src={arrowWhite} alt='arrow'></img>
                    </div>
                
            </section>


            <section>
                <BookSection />
            </section>


            <section>
                <ContactSection />
            </section>

            <section className='p-8 gap-4 footer flex flex-wrap justify-between bg-neutral-100 text-xs text-neutral-400'>
                <p>Privacy Policy</p>
                <p>Cookies</p>
                <p>Preferences</p>
                <p>Our Products</p>
                <p>©2021 Playsce. All Rights Reserved.</p>
            </section>

        </div>
    )
}

export default Home
