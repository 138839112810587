import { useState } from 'react';
import './App.css';
import Home from './pages/Home/Home';
import Menu from './pages/Home/Menu';
import Navbar from './pages/Home/Navbar';

function App() {

  const [isOpen, setIsOpen] = useState(false)

    const toggleNavbar = () => {
        setIsOpen(!isOpen)
    }

  return (
    <div className='w-vw' >

      <Navbar menuToggle={toggleNavbar} />

      {isOpen && <section className='fixed'>
        <Menu menuToggle={toggleNavbar}/>
      </section>}

      {!isOpen && <Home />}
    </div>
  );
}

export default App;
