import React from 'react'

import chatIcon from '../../assets/ui/chatIcon.svg'
import arrowBlack from '../../assets/ui/arrow-b.svg'

function ContactSection() {
    return (
        <div>

<section className='contact-section grid md:grid-flow-col md:divide-x bg-neutral-100 text-2xl font-light text-neutral-800'>

                <div className='h-96 flex flex-col justify-end md:justify-center lg:h-screen font-normal text-4xl md:text-7xl border-2 border-transparent border-b-neutral-200'>
                    <p className='px-8 md:px-16'>Let's chat,</p>
                    <div className='px-8 md:px-16 pb-16 bg-clip-text bg-gradient-to-r from-rose-500 via-blue-400 to-purple-900 text-transparent'>
                        <h1>we respond fast</h1>
                    </div>
                </div>
                <div>
                    <section className='grid grid-flow-row lg:grid-cols-2 lg:h-screen'>
                        
                        <div className='flex flex-row justify-between items-end py-12 px-8 md:px-16 mt-32 lg:mt-0 border-2 border-transparent border-b-neutral-200 md:border-r-neutral-200'>
                            <div className='grid grid-flow-row gap-6'>
                                <img className='w-6' src={chatIcon} alt='chat icon'></img>
                                <p className='w-32'>Chat with us now</p>
                            </div>
                            <img src={arrowBlack} alt='arrow'></img>
                        </div>
                        <div className='flex flex-row justify-between items-end py-12 px-8 md:px-16 mt-32 lg:mt-0 border-2 border-transparent border-b-neutral-200'>
                            <div className='grid grid-flow-row gap-6'>
                                <img className='w-6' src={chatIcon} alt='chat icon'></img>
                                <p className='w-32'>Speak on the phone</p>
                            </div>
                            <img src={arrowBlack} alt='arrow'></img>
                        </div>
                        <div className='flex flex-row justify-between items-end py-12 px-8 md:px-16 mt-32 lg:mt-0 border-2 border-transparent border-b-neutral-200 md:border-r-neutral-200'>
                            <div className='grid grid-flow-row gap-6'>
                                <img className='w-6' src={chatIcon} alt='chat icon'></img>
                                <p className='w-32'>Book a consultation</p>
                            </div>
                            <img src={arrowBlack} alt='arrow'></img>
                        </div>
                        <div className='flex flex-row justify-between items-end py-12 px-8 md:px-16 mt-32 lg:mt-0 border-2 border-transparent border-b-neutral-200'>
                            <div className='grid grid-flow-row gap-6'>
                                <img className='w-6' src={chatIcon} alt='chat icon'></img>
                                <p className='w-28'>Send us an email</p>
                            </div>
                            <img src={arrowBlack} alt='arrow'></img>
                        </div>
                    
                    </section>
                </div>
                
            </section>
            
        </div>
    )
}

export default ContactSection
