import React from 'react'

import arrowWhite from '../../assets/ui/arrow-w.svg'
import SquareGrid from '../../templates/SquareGrid'

function OpeningGrid() {
    return (
        <div className='w-full h-screen grid lg:grid-cols-2 divide-y lg:divide-x divide-neutral-800 border border-transparent border-b-neutral-800'>
            <div className='flex flex-col gap-16 justify-end lg:justify-center p-8 pt-36 lg:pt-8 text-4xl lg:text-7xl lg:p-16'>
                <div>
                    <p>We help brands</p>
                    <p className='bg-clip-text bg-gradient-to-r from-rose-100 via-rose-400 to-purple-900 text-transparent'>
                        that do good <br /> look good.
                    </p>
                </div>
                <div className=' flex flex-row justify-between w-40 border rounded-full p-4 pl-6 text-sm font-light'>
                    <p >Our Services</p>
                <img src={arrowWhite} alt='arrow'></img>
                </div>
            </div>
            <SquareGrid />
        </div>
    )
}

export default OpeningGrid
