import React from 'react';
import reactDom from 'react-dom';


import playsceLogo from '../../assets/ui/playsce-logo.svg'
import hamburger from '../../assets/ui/hamburger.svg'


function Navbar(props) {
    return reactDom.createPortal(
        <div className='navbar z-50 w-full fixed flex flex-row justify-between px-6 py-6 md:px-16 md:pt-16 '>
            <div className='invert-color  w-8 h-8 md:w-12 flex flex-col justify-center'><img className='w-full' src={playsceLogo} alt='playsce logo' ></img></div>
            <div className=' w-6 h-6 md:w-8 flex flex-col justify-center' onClick={props.menuToggle}><img className='w-full' src={hamburger} alt='playsce logo' ></img></div>
        </div>,
        document.getElementById('navbar')
    )
}

export default Navbar
