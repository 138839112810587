
      import React, { useRef, useState } from "react";
    // Import Swiper React components
    import { Swiper, SwiperSlide } from "swiper/react";
    
    // Import Swiper styles
    import "swiper/css";
    import "swiper/css/scrollbar"
    
    import "./Carousel.css";

    //assets
    import projectOne from '../../assets/projects/project1/one.png'
    import projectTwo from '../../assets/projects/project2/two.png'
    import projectThree from '../../assets/projects/project3/three.png'
    import projectFour from '../../assets/projects/project4/four.png'
    import projectFive from '../../assets/projects/project5/five.png'
    import arrowWhite from '../../assets/ui/arrow-w.svg'
    
    
    // import Swiper core and required modules
    import SwiperCore, {
        Scrollbar
      } from 'swiper';
    
    // install Swiper modules
    SwiperCore.use([Scrollbar]);
    
    
    export default function App() {
      
      
      
      return (
        <>
        <Swiper slidesPerView={'auto'} scrollbar={{
  "hide": false
    }} className="mySwiper">
      {/* <SwiperSlide style={{backgroundImage: `url(${projectOne})`}}>Slide 1</SwiperSlide> */}
      <SwiperSlide>
          <img src={projectTwo} alt="project"></img>
            <section className="absolute w-full h-full font-thin text-4xl">
                <div className="flex flex-col items-start justify-between h-full">
                    <div className=" text-left">
                        <p>Wurlet</p>
                        <p>Go beyond the expected</p>
                    </div>
                    <div className=' flex flex-row justify-between w-48 border rounded-full p-4 pl-6 text-sm font-light'>
                        <p >Our Services</p>
                        <div style={{backgroundImage: `url(${arrowWhite})`}}></div>
                    </div>
                </div>
            </section>
      </SwiperSlide>
      
      <SwiperSlide>
          <img src={projectThree} alt="project"></img>
      </SwiperSlide>
      <SwiperSlide>
          <img src={projectFive} alt="project"></img>
      </SwiperSlide>
      <SwiperSlide>
          <img src={projectFour} alt="project"></img>
      </SwiperSlide>
      <SwiperSlide>
          <img src={projectOne} alt="project"></img>
      </SwiperSlide>
      </Swiper>
        </>
      )
    }