import React from 'react'

import arrowWhite from '../assets/ui/arrow-w.svg'

function SquareGrid() {
    return (
        <div className='w-full h-full grid grid-cols-2 text-2xl lg:text-4xl font-extralight divide-x divide-zinc-800'>

            <div className='p-8 md:p-16 flex items-end justify-between border border-transparent border-b-zinc-800'>
                <p>Brand</p>
                <img className='pb-2' src={arrowWhite} alt='arrow'></img>
            </div>
            <div className='p-8 md:p-16 flex items-end justify-between border border-transparent border-b-zinc-800'>
                <p>Video</p>
                <img className='pb-2' src={arrowWhite} alt='arrow'></img>
            </div>
            <div className='p-8 md:p-16 flex items-end justify-between'>
                <p>Website</p>
                <img className='pb-2' src={arrowWhite} alt='arrow'></img>
            </div>
            <div className='p-8 md:p-16 flex items-end justify-between'>
                <p>Social</p>
                <img className='pb-2' src={arrowWhite} alt='arrow'></img>
            </div>

        </div>
    )
}

export default SquareGrid
