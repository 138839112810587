import React from 'react'

import arrowBlack from '../../assets/ui/arrow-b.svg'

function BookSection() {
    return (
        <div>
            <section className=' grid gap-10 bg-neutral-200 text-neutral-900 px-8 lg:px-16 py-24'>
                <p className=' text-4xl lg:text-5xl lg:w-96 font-light'>Book your free digital marketing consultation.</p>
                <p className=' font-thin'>Want to know understand the digital health of your business?<br/> Book a free 30 minute slot below or speak right now.</p>
                <div className='grid gap-4 pt-6'>
                    <div className='w-40 flex flex-row justify-between border border-neutral-800 rounded-full p-4 pl-6 text-sm font-light'>
                        <p >Send an email</p>
                        <img src={arrowBlack} alt='arrow'></img>
                    </div>
                    <div className='w-40 flex flex-row justify-between border border-neutral-800 rounded-full p-4 pl-6 text-sm font-light'>
                        <p >Call us</p>
                        <img src={arrowBlack} alt='arrow'></img>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default BookSection
