import React from 'react'

/////TWO GRID TEMPLATE

import arrowWhite from '../../assets/ui/arrow-w.svg'
import AnimationOne from '../../assets/ui/rings.svg'

function SectionTwo() {
    return (
        <div className='w-full lg:h-large grid lg:grid-cols-2 border border-transparent border-b-neutral-800'>
            
            <div className='h-fit flex flex-row justify-center'>
                <img className='w-full p-8 lg:p-16' src={AnimationOne} alt='animation'></img>
            </div>

            <div className=' flex flex-col justify-center gap-8 items-center lg:items-start lg:pr-52 text-4xl font-extralight px-8 pb-20 text-center lg:text-left '>
                <p className=' bg-clip-text bg-gradient-to-r from-rose-100 via-rose-400 to-purple-900 text-transparent'>
                We drive growth for your business making your brand more compelling online.
                </p>
                <div className=' flex flex-row justify-between w-40 border rounded-full p-4 pl-6 text-sm font-light'>
                    <p >Our Services</p>
                <img src={arrowWhite} alt='arrow'></img>
                </div>
            </div>

        </div>
    )
}

export default SectionTwo
